import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
} from 'react-router';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import type { Route } from './+types/root';
import stylesheet from './main.css?url';
import { Button } from './components/ui/button';

export const links: Route.LinksFunction = () => [
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  {
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com',
    crossOrigin: 'anonymous',
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap',
  },
  { rel: 'stylesheet', href: stylesheet },
];

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="">
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default function App() {
  useEffect(() => {
    if (
      import.meta.env.MODE === 'production' &&
      import.meta.env.VITE_CLARITY_ID
    ) {
      import('@microsoft/clarity').then((Clarity) => {
        Clarity.default.init(import.meta.env.VITE_CLARITY_ID);
      });
    }
  }, []);
  return <Outlet />;
}

export function ErrorBoundary({ error }: Route.ErrorBoundaryProps) {
  let message = 'Oops!';
  let details = 'An unexpected error occurred.';
  let stack: string | undefined;
  if (isRouteErrorResponse(error)) {
    message = error.status === 404 ? '404' : 'Error';
    details =
      error.status === 404
        ? 'The requested page could not be found.'
        : error.statusText || details;
  } else if (error && error instanceof Error) {
    Sentry.captureException(error);

    if (import.meta.env.DEV) {
      details = error.message;
      stack = error.stack;
    }
  }

  const reloadPage = () => {
    window.location.reload();
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <main className="flex items-center justify-center min-h-screen ">
      <div className="max-w-xl w-full  shadow-md rounded-lg p-6 space-y-4">
        <div className="flex items-start space-x-4">
          <div className="text-4xl ">⚠️</div>
          <div className="space-y-2">
            <h1 className="text-xl font-bold ">{message}</h1>
            <p className="text-sm ">{details}</p>
          </div>
        </div>
        {stack && (
          <details className="rounded-md p-4 text-sm ">
            <summary className="cursor-pointer ">Stack trace</summary>
            <pre className="mt-2 overflow-x-auto">
              <code>{stack}</code>
            </pre>
          </details>
        )}
        <div className="flex justify-end">
          {message && message == '404' ? (
            <Button variant="secondary" onClick={goBack} aria-label="Go Back">
              Go back
            </Button>
          ) : (
            <Button
              variant="secondary"
              onClick={reloadPage}
              aria-label="Reload Page"
            >
              Reload
            </Button>
          )}
        </div>
      </div>
    </main>
  );
}
